import Vue from 'vue'

import compassApi from '@/services/compass-api'
import colorPalettes from '@/services/color-palettes'

import Portfolio from '@/models/Portfolio'
import Building from '@/models/Building'

import de from '@/locales/de.json'

const state = {
  portfolios: null, // List of portfolios (with or without details)
  portfolios_loading: false,
  portfolios_loaded: false,
  exportLoading: false,
  reidaEnergyCarriers: {},
  //
  // Move to sustainabilityIndicatorFactors module?
  //
  sustainabilityIndicators: [], // List of energy carrier sustainability indicators
  // Maybe rename?
  defaultSustainabilityIndicatorIdentifiers: [
    //
    's12e',
    's123e',
    's1e',
    's2e',
    's3e',
    'ee',
    'pe',
    'penr',
    'per',
  ],
  emissionsSustainabilityIndicatorIdentifiers: ['s12e', 's123e', 's1e', 's2e', 's3e'],
  energySustainabilityIndicatorIdentifiers: ['ee', 'pe', 'penr', 'per'],
  // Add inferred identifiers here too?
  // ...
}

const bigTargetUnitFct = (sustainabilityIndicator) => {
  if (sustainabilityIndicator.unit === 'kg CO₂e/kWh') {
    return 't CO₂e'
  } else if (sustainabilityIndicator.unit === 'kWh/kWh') {
    return 'MWh'
  }
  return null
}

const specificTargetUnitFct = (sustainabilityIndicator) => {
  if (sustainabilityIndicator.unit === 'kg CO₂e/kWh') {
    return 'kg CO₂e/m²'
  } else if (sustainabilityIndicator.unit === 'kWh/kWh') {
    return 'kWh/m²'
  }
  return null
}

// Getters
const getters = {
  getAll(state) {
    return state.portfolios
  },

  getById(state) {
    return (portfolioId) => {
      if (state.portfolios === null) {
        return undefined
      }
      return state.portfolios.find((p) => p.id === portfolioId)
    }
  },

  getReidaEnergyCarriers(state) {
    return () => {
      if (state.portfolio === null) {
        return undefined
      }
      return state.reidaEnergyCarriers
    }
  },

  getSustainabilityIndicators(state) {
    return () => {
      if (state.portfolios === null) {
        return undefined
      }
      // Add missing/inferred sustainability indicators (s12e, s123e, per)
      return [
        ...state.sustainabilityIndicators,
        ...[
          { identifier: 's12e', name: 'Scope 1-2 Emissionen', unit: 'kg CO₂e/kWh' },
          { identifier: 's123e', name: 'Scope 1-3 Emissionen', unit: 'kg CO₂e/kWh' },
          { identifier: 'per', name: 'Primärenergie (erneuerbar)', unit: 'kWh/kWh' },
        ],
      ]
    }
  },

  getEnhancedSustainabilityIndicators(state, getters) {
    return () => {
      const indicators = getters.getSustainabilityIndicators()
      return indicators.map((si) => {
        return {
          ...si,
          getSpecificTargetUnit: () => specificTargetUnitFct(si),
          getBigTargetUnit: () => bigTargetUnitFct(si),
        }
      })
    }
  },

  getEnhancedSustainabilityIndicatorByIdentifier(state, getters) {
    return (identifier) => {
      const indicators = getters.getSustainabilityIndicators()
      if (indicators === undefined || indicators.length === 0) {
        return undefined
      }
      const result = indicators.find((i) => i.identifier === identifier)
      if (result === undefined) {
        return null
      }
      return {
        ...result,
        getSpecificTargetUnit: () => specificTargetUnitFct(result),
        getBigTargetUnit: () => bigTargetUnitFct(result),
      }
    }
  },

  isDefaultSustainabilityIndicator(state) {
    return (identifier) => {
      return state.defaultSustainabilityIndicatorIdentifiers.includes(identifier)
    }
  },
}

function extractExportTranslations(lang) {
  const result = {}
  result['header'] = lang.export.header
  result['scenarioHeader'] = lang.export.scenarioHeader
  result['heatingTypes'] = lang._heatingTypes
  result['usageTypes'] = lang._usageTypes
  result['dataQuality'] = lang._dataQuality
  result['buildingTags'] = {
    feasibility_ews: lang._buildingTags.feasibility_ews,
    feasibility_gw: lang._buildingTags.feasibility_gw,
    availability_district: lang._buildingTags.availability_district,
  }
  return result
}

// Actions
const actions = {
  /*
   * Loaders
   */
  // Load list of all portfolios (compact summary)
  async loadAll({ state, commit }) {
    if (!state.portfolios_loading) {
      commit('portfoliosLoading')
      const portfolios = await compassApi.call('/portfolios')
      commit('portfoliosLoaded', { portfolios })
    }
  },

  async refreshAll({ commit }) {
    commit('portfoliosLoading')
    const portfolios = await compassApi.call('/portfolios')
    commit('portfoliosLoaded', { portfolios })
  },

  // Load details of one portfolio
  async loadDetailsById({ state, commit, dispatch }, portfolioId) {
    if (state.portfolios_loaded) {
      const portfolio = state.portfolios.find((p) => p.id === portfolioId)
      if (portfolio === undefined || (!portfolio.__details_loading && !portfolio.__details_loaded)) {
        commit('detailsLoading', { portfolioId })
        const details = await compassApi.call(`/portfolios/${portfolioId}`)
        commit('detailsLoaded', { portfolioId, details })
        // Update permissions for portfolio
        dispatch('permissions/setPortfolioPermissions', details?.permissions, { root: true })
      }
    }
  },

  async loadReidaEnergyCarriers({ commit }) {
    commit('reidaEnergyCarriersLoading')
    const reidaEnergyCarriers = await compassApi.call(`/energy_carriers/reida`)
    commit('reidaEnergyCarriersLoaded', { reidaEnergyCarriers })
  },

  // Load list of energy carrier sustainability indicators of one portfolio
  async loadSustainabilityIndicatorsByPortfolioId({ state, commit }, portfolioId) {
    if (state.portfolios_loaded) {
      const portfolio = state.portfolios.find((p) => p.id === portfolioId)
      if (portfolio === undefined || !(portfolio.__details_loading && portfolio.__details_loaded)) {
        commit('sustainabilityIndicatorsLoading')
        const sustainabilityIndicators = await compassApi.call(`/portfolios/${portfolioId}/sustainability/indicators`)
        commit('sustainabilityIndicatorsLoaded', { sustainabilityIndicators })
      }
    }
  },

  // Refresh details of one portfolio
  async refreshDetailsById({ commit, dispatch }, portfolioId) {
    commit('detailsLoading', { portfolioId })
    const details = await compassApi.call(`/portfolios/${portfolioId}`)
    commit('detailsLoaded', { portfolioId, details })
    // Update permissions for portfolio
    dispatch('permissions/setPortfolioPermissions', details?.permissions, { root: true })
  },

  // Load list of users for one portfolio
  async loadUsersById({ state, commit }, portfolioId) {
    if (state.portfolios_loaded) {
      const portfolio = state.portfolios.find((p) => p.id === portfolioId)
      if (portfolio === undefined || (!portfolio.__users_loading && !portfolio.__users_loaded)) {
        commit('usersLoading', { portfolioId })
        const users = await compassApi.call(`/portfolios/${portfolioId}/users`)
        commit('usersLoaded', { portfolioId, users })
      }
    }
  },

  // Refresh list of users for one portfolio
  async refreshUsersById({ commit }, portfolioId) {
    commit('usersLoading', { portfolioId })
    const users = await compassApi.call(`/portfolios/${portfolioId}/users`)
    commit('usersLoaded', { portfolioId, users })
  },

  // Load buildings for one portfolio
  async loadBuildingsById({ commit, state, rootState }, portfolioId) {
    if (state.portfolios_loaded) {
      const portfolio = state.portfolios.find((p) => p.id === portfolioId)
      if (portfolio === undefined || (!portfolio.__buildings_loading && !portfolio.__buildings_loaded)) {
        const query = JSON.stringify(rootState.query)
        commit('buildingsLoading', { portfolioId })
        const buildings = await compassApi.call(`/portfolios/${portfolioId}/buildings?query=${query}`)
        commit('buildingsLoaded', { portfolioId, buildings })
      }
    }
  },

  /*
   * Create, edit and delete Portfolios
   */
  async updatePortfolio({ dispatch }, { id, portfolio }) {
    const updated = await compassApi.call(`/portfolios/${id}`, 'PUT', portfolio)
    dispatch('refreshDetailsById', id)
    return new Portfolio(updated)
  },

  async deletePortfolio({ dispatch }, id) {
    await compassApi.call(`/portfolios/${id}`, 'DELETE')
    dispatch('refreshAll')
  },

  async addPortfolio({ dispatch }, portfolio) {
    const newPortfolio = await compassApi.call(`/portfolios`, 'POST', portfolio)
    dispatch('refreshAll')
    return new Portfolio(newPortfolio)
  },

  async updatePortfolioInactiveAfter({ dispatch }, { id, inactiveAfter }) {
    await compassApi.call(`/portfolios/${id}/inactive-after`, 'PUT', { inactive_after: inactiveAfter })
    dispatch('refreshDetailsById', id)
  },

  async updatePortfolioName({ dispatch }, { id, name }) {
    await compassApi.call(`/portfolios/${id}/name`, 'PUT', { name: name })
    dispatch('refreshDetailsById', id)
  },

  async restoreUsageParams({ dispatch }, { id }) {
    await compassApi.call(`/portfolios/${id}/params/restore`, 'PUT')
    dispatch('refreshDetailsById', id)
  },

  async updateParams({ dispatch }, { id, params }) {
    await compassApi.call(`/portfolios/${id}/params`, 'PUT', params)
    dispatch('refreshDetailsById', id)
  },

  async updateParamsSane({ dispatch }, { id, params }) {
    await compassApi.call(`/portfolios/${id}/params-sane`, 'PUT', params)
    dispatch('refreshDetailsById', id)
  },

  async addPortfolioUser({ dispatch }, { portfolioId, userId, role }) {
    await compassApi.call(`/portfolios/${portfolioId}/users`, 'POST', { id: userId, role: role })
    dispatch('refreshUsersById', portfolioId)
    dispatch('users/loadAll', {}, { root: true })
  },

  async removePortfolioUser({ dispatch }, { portfolioId, userId }) {
    await compassApi.call(`/portfolios/${portfolioId}/users/${userId}`, 'DELETE')
    dispatch('refreshUsersById', portfolioId)
    dispatch('users/loadAll', {}, { root: true })
  },

  /*
   * Create, edit and delete Targets
   */
  async addTarget({ dispatch }, target) {
    await compassApi.call(`/targets`, 'POST', target)
    dispatch('refreshDetailsById', target.portfolio_id)
  },

  async updateTarget({ dispatch }, { id, target }) {
    await compassApi.call(`/targets/${id}`, 'PUT', target)
    dispatch('refreshDetailsById', target.portfolio_id)
  },

  async deleteTarget({ dispatch }, target) {
    await compassApi.call(`/targets/${target.id}`, 'DELETE')
    dispatch('refreshDetailsById', target.portfolio_id)
  },

  /*
   * Create, edit and delete Tags and TagGroups
   */
  async addTagGroup({ dispatch }, tagGroup) {
    await compassApi.call(`/tags/groups`, 'POST', tagGroup)
    dispatch('refreshDetailsById', tagGroup.portfolio_id)
  },

  async updateTagGroup({ dispatch }, { id, tagGroup }) {
    await compassApi.call(`/tags/groups/${id}`, 'PUT', tagGroup)
    dispatch('refreshDetailsById', tagGroup.portfolio_id)
  },

  async deleteTagGroup({ dispatch }, tagGroup) {
    await compassApi.call(`/tags/groups/${tagGroup.id}`, 'DELETE')
    dispatch('refreshDetailsById', tagGroup.portfolio_id)
  },

  async addTag({ dispatch }, tag) {
    await compassApi.call(`/tags/tags`, 'POST', tag)
    // dispatch('refreshDetailsById', id)
  },

  async updateTag({ dispatch }, { id, tag }) {
    await compassApi.call(`/tags/tags/${id}`, 'PUT', tag)
    // dispatch('refreshDetailsById', id)
  },

  async deleteTag({ dispatch }, tag) {
    await compassApi.call(`/tags/tags/${tag.id}`, 'DELETE')
    // dispatch('refreshDetailsById', id)
  },

  /*
   * Create, edit and delete EnergyCarrier
   */
  async addEnergyCarrier({ dispatch }, energyCarrier) {
    await compassApi.call(`/energy_carriers`, 'POST', energyCarrier)
    dispatch('sustainabilityIndicatorFactors/refresh', {}, { root: true })
    dispatch('refreshDetailsById', energyCarrier.portfolio_id)
  },

  async updateEnergyCarrier({ dispatch }, { id, energyCarrier }) {
    //
    await compassApi.call(`/energy_carriers/${id}`, 'PUT', energyCarrier)
    dispatch('sustainabilityIndicatorFactors/refresh', {}, { root: true })
    //
    // dispatch('sustainabilityIndicatorFactors/update', {
    //   portfolioId: energyCarrier.portfolio_id,
    //   yearId,
    //   sustainabilityIndicatorFactors: sustainabilityIndicators
    // }, { root: true })
    dispatch('refreshDetailsById', energyCarrier.portfolio_id)
  },

  async deleteEnergyCarrier({ dispatch }, { id, portfolioId }) {
    await compassApi.call(`/energy_carriers/${id}`, 'DELETE')
    dispatch('sustainabilityIndicatorFactors/refresh', {}, { root: true })
    dispatch('refreshDetailsById', portfolioId)
  },

  /*
   * Create, edit and delete HeatGrids
   */
  async addHeatGrid({ dispatch }, heatGrid) {
    await compassApi.call(`/heat_grids`, 'POST', heatGrid)
    dispatch('refreshDetailsById', heatGrid.portfolio_id)
  },

  async updateHeatGrid({ dispatch }, { id, heatGrid }) {
    await compassApi.call(`/heat_grids/${id}`, 'PUT', heatGrid)
    dispatch('refreshDetailsById', heatGrid.portfolio_id)
  },

  async deleteHeatGrid({ dispatch }, heatGrid) {
    await compassApi.call(`/heat_grids/${heatGrid.id}`, 'DELETE')
    dispatch('refreshDetailsById', heatGrid.portfolio_id)
  },

  /*
   * Create, edit and delete HeatingType
   */
  async addHeatingType({ dispatch }, heatingType) {
    await compassApi.call(`/heating_types`, 'POST', heatingType)
    dispatch('refreshDetailsById', heatingType.portfolio_id)
  },

  async updateHeatingType({ dispatch }, { id, heatingType }) {
    await compassApi.call(`/heating_types/${id}`, 'PUT', heatingType)
    dispatch('refreshDetailsById', heatingType.portfolio_id)
  },

  async deleteHeatingType({ dispatch }, { id, portfolioId }) {
    await compassApi.call(`/heating_types/${id}`, 'DELETE')
    dispatch('refreshDetailsById', portfolioId)
  },

  /*
   * Create, edit and delete RenovationStandards
   */
  async addRenovationStandard({ dispatch }, renovationStandard) {
    await compassApi.call(`/renovation_standards`, 'POST', renovationStandard)
    dispatch('refreshDetailsById', renovationStandard.portfolio_id)
  },

  async updateRenovationStandard({ dispatch }, { id, renovationStandard }) {
    await compassApi.call(`/renovation_standards/${id}`, 'PUT', renovationStandard)
    dispatch('refreshDetailsById', renovationStandard.portfolio_id)
  },

  async deleteRenovationStandard({ dispatch }, renovationStandard) {
    await compassApi.call(`/renovation_standards/${renovationStandard.id}`, 'DELETE')
    dispatch('refreshDetailsById', renovationStandard.portfolio_id)
  },

  /*
   * Create, edit and delete Scenarios
   */
  async resetScenarios({ commit }, portfolioId) {
    await compassApi.call(`/portfolios/${portfolioId}/resetscenarios`, 'PUT')
    const portfolios = await compassApi.call('/portfolios')
    commit('portfoliosLoaded', { portfolios })
  },

  async addScenario({ dispatch }, scenario) {
    const result = await compassApi.call(`/scenarios`, 'POST', scenario)
    dispatch('refreshDetailsById', scenario.portfolio_id)
    return result
  },

  async updateScenario({ dispatch }, scenario) {
    await compassApi.call(`/scenarios/${scenario.id}`, 'PUT', scenario)
    dispatch('refreshDetailsById', scenario.portfolio_id)
  },

  async deleteScenario({ dispatch }, scenario) {
    await compassApi.call(`/scenarios/${scenario.id}`, 'DELETE')
    dispatch('refreshDetailsById', scenario.portfolio_id)
  },

  async duplicateScenarioWithMeasures({ dispatch }, { scenarioId, portfolioId, description }) {
    const result = await compassApi.call(`/scenarios/${scenarioId}/copy`, 'POST', { description: description })
    dispatch('refreshDetailsById', portfolioId)
    return result
  },

  /*
   * Import SustainabilityIndicators and their value for the EnergyCarriers
   */
  async importSustainabilityIndicatorsAndValues({ dispatch }, { data, portfolioId }) {
    await compassApi.call(`/portfolios/${portfolioId}/sustainability-indicator-factor/import`, 'POST', data)
    dispatch('refreshDetailsById', portfolioId)
  },

  async exportPortfolioBuildings({ commit, rootState }, portfolioId) {
    commit('setExportLoading', { loading: true })
    try {
      const query = JSON.stringify(rootState.query)
      const translations = extractExportTranslations(de)
      await compassApi.fetchFile(`/export/portfolio/${portfolioId}/buildings?query=${query}`, 'POST', translations)
    } finally {
      commit('setExportLoading', { loading: false })
    }
  },
}

// Mutations
const mutations = {
  // When buildings are being loaded
  buildingsLoading(state, { portfolioId }) {
    let portfolio = state.portfolios.find((o) => o.id === portfolioId)
    Vue.set(portfolio, '__buildings_loading', true)
    // Vue.set(portfolio, '__buildings_loaded', false)
    Vue.set(state, 'portfolios', [...state.portfolios])
  },

  // When buildings have been loaded
  buildingsLoaded(state, { portfolioId, buildings }) {
    let portfolio = state.portfolios.find((o) => o.id === portfolioId)
    Vue.set(portfolio, '__buildings_loading', false)
    Vue.set(portfolio, '__buildings_loaded', true)
    Vue.set(portfolio, 'buildings', [...buildings.buildings.map((b) => new Building(b))])
    Vue.set(portfolio, 'tag_counts', buildings.tag_counts)
    Vue.set(state, 'portfolios', [...state.portfolios])
  },

  // When users are being loaded
  usersLoading(state, { portfolioId }) {
    let portfolio = state.portfolios.find((o) => o.id === portfolioId)
    Vue.set(portfolio, '__users_loading', true)
    // Vue.set(portfolio, '__users_loaded', false)
    Vue.set(state, 'portfolios', [...state.portfolios])
  },

  // When users have been loaded
  usersLoaded(state, { portfolioId, users }) {
    let portfolio = state.portfolios.find((o) => o.id === portfolioId)
    Vue.set(portfolio, '__users_loading', false)
    Vue.set(portfolio, '__users_loaded', true)
    Vue.set(portfolio, 'users', users)
    Vue.set(state, 'portfolios', [...state.portfolios])
  },

  // When details are being loaded
  detailsLoading(state, { portfolioId }) {
    let portfolio = state.portfolios.find((o) => o.id === portfolioId)
    if (portfolio === undefined) {
      return this.dispatch('/', { root: true })
    }
    Vue.set(portfolio, '__details_loading', true)
    // Vue.set(portfolio, '__details_loaded', false)
    Vue.set(state, 'portfolios', [...state.portfolios])
  },

  // When details have been loaded
  detailsLoaded(state, { portfolioId, details }) {
    const sortedScenarios = [...details.scenarios].sort((a, b) => (a.description > b.description ? 1 : -1))
    details.scenarios = sortedScenarios.map((s, i) => {
      s.color = colorPalettes.scenarioColors[i % colorPalettes.scenarioColors.length]
      return s
    })
    let portfolios = state.portfolios.map((portfolio) => {
      if (portfolio.id === portfolioId) {
        return new Portfolio({
          ...portfolio,
          ...details,
          __details_loading: false,
          __details_loaded: true,
        })
      } else {
        return portfolio
      }
    })
    Vue.set(state, 'portfolios', [...portfolios])
  },

  // When reidaEnergyCarriers are being loaded
  reidaEnergyCarriersLoading(state) {
    Vue.set(state, 'reidaEnergyCarriers', [])
  },

  // When reidaEnergyCarriers have been loaded
  reidaEnergyCarriersLoaded(state, { reidaEnergyCarriers }) {
    Vue.set(state, 'reidaEnergyCarriers', [...reidaEnergyCarriers])
  },

  // When sustainabilityIndicators are being loaded
  sustainabilityIndicatorsLoading(state) {
    Vue.set(state, 'sustainabilityIndicators', [])
  },

  // When sustainabilityIndicators have been loaded
  sustainabilityIndicatorsLoaded(state, { sustainabilityIndicators }) {
    Vue.set(state, 'sustainabilityIndicators', [...sustainabilityIndicators])
  },

  // When portfolios are being loaded
  portfoliosLoading(state) {
    Vue.set(state, 'portfolios_loading', true)
    Vue.set(state, 'portfolios_loaded', false)
  },

  // After portfolios have been loaded
  portfoliosLoaded(state, { portfolios }) {
    portfolios.sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
    portfolios.forEach((p) => {
      p.__buildings_loading = false
      p.__buildings_loaded = false
      p.__details_loading = false
      p.__details_loaded = false
      p.__users_loading = false
      p.__users_loaded = false
    })
    state.portfolios = portfolios.map((p) => new Portfolio(p))
    Vue.set(state, 'portfolios_loading', false)
    Vue.set(state, 'portfolios_loaded', true)
  },

  setExportLoading(state, { loading }) {
    Vue.set(state, 'exportLoading', loading)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
